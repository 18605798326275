import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'

const AppCompanyNewsBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CompanyNewsBannerSection {
    height: 70vh;
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 96px 50px 96px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 80px 30px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .CompanyNewsHeading {
    font-size: 40px;
    padding-top: 15%;
    @media (max-width: 1650px) {
      padding-top: 0%;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
  }
  .NewsBannerImageSection {
    width: 50%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .companyNewsBannerImage {
    width: 100%;
    height: auto;
  }
  .CustomerBannerPara {
    margin: 40px 0px 0px 0px;
    /* padding-right: 250px; */
  }
  .NewsBannerImageSection {
    width: 45%;
  }
  .CustomersBannerContentSection {
    width: 51%;
    @media (max-width: 1920px) {
      /* width: 42%; */
    }
  }
`

export const AppCompanyNewsBanner = () => {
  return (
    <AppCompanyNewsBannerWapper>
      <div className="CompanyNewsBannerSection">
        <div className="CustomersBannerContentSection">
          <ReUseHOneTag Heading="Meet Our Customers" HeadingStyle="CompanyNewsHeading" />
          <ReUsePtag
            para="Leading enterprises trust Raindrop’s Spend Management solutions for their automation and digital transformation spend management needs."
            paraStyle="CustomerBannerPara"
          />
        </div>
        <div className="NewsBannerImageSection">
          <ReuseImgTag
            ImageStyle="companyNewsBannerImage"
            ImagePath="https://storage.googleapis.com/raindroppublic/website_data/Raindrop_Customers_banner.png"
            AltName="Company-News-Banner-image"
          />
        </div>
      </div>
    </AppCompanyNewsBannerWapper>
  )
}
