import React from 'react'
import styled from 'styled-components'
import { AppVideoBanner } from './AppVideoBanner'
import JeffHomevideoData from '../Data/JeffHomevideoData.json'
// import { ReUseHTwotag } from './ReUseHTwoTag'

const AppJeffHomeBannerWapper = styled.div`
  .JeffcontainSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 50px 30px 50px 30px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 50px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 30px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .JeffBannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .JeffHeadingSize {
    text-align: left;
    max-width: 780px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2rem;
    /* margin: auto; */
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .JeffBannerPara {
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }
  .JeffBannerImage {
    border: 2px solid #34a1d5;
    border-radius: 40px;
    width: 100%;
    height: auto;
    float: right;
    @media (max-width: 1390px) {
      width: 100%;
      float: unset;
    }
  }
  .JeffRaindropListView {
    display: none;
  }
  .JeffSectionButton {
    display: none;
  }
  .JeffBannerVideoSection {
    width: 65%;
    @media (max-width: 1390px) {
      width: 100%;
    }
  }
  .JeffBannerContent {
    /* margin-top: 3%; */
    @media (max-width: 1650px) {
      margin-top: unset;
    }
  }
  .CustomerHeadingby {
    margin-top: 60px;
    text-align: center;
    padding: 0% 0% 2% 0%;
  }
`

export const AppJeffHomeBanner = () => {
  return (
    <AppJeffHomeBannerWapper>
      {/* <ReUseHTwotag Heading="Customer Stories" HeadingStyle="CustomerHeadingby" /> */}
      {JeffHomevideoData.map((data, index) => {
        return (
          <div key={index}>
            <AppVideoBanner
              containSection="JeffcontainSection"
              EventBannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              ButtonContent={data.ButtonContent}
              InternalPageShiftUrl={data.ShiftInternalPath}
              ButtonStyle="JeffSectionButton"
              BannerSectionOne="JeffBannerSectionOne"
              BannerSectionTwo="BannerSectionTwo"
              EventBannerHeadingSize="JeffHeadingSize"
              EventBannerPara="JeffBannerPara"
              EventBannerImage="JeffBannerImage"
              UlStyle="JeffRaindropListView"
              EventBannerSectionTwo="JeffBannerVideoSection"
              EventBannerSectionOne="JeffBannerContent"
            />
          </div>
        )
      })}
    </AppJeffHomeBannerWapper>
  )
}
